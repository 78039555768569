<template>
  <v-row>
    <v-col
      cols="12"
      xs="6"
      class="text-end"
    >
      <v-btn small elevation="10" class="text-xs font-weight-semibold" color="primary darken-2" :to="{ name: 'contact_us' }">
        <font-awesome-icon icon="fa-solid fa-minus" class="pe-1"/>
        <v-spacer></v-spacer>
        Create New Link
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <traffic-weekly-overview></traffic-weekly-overview>
    </v-col>

    <v-col
      cols="12"
      md="4"
      sm="6"
    >
      <traffic-card-total-earning></traffic-card-total-earning>
    </v-col>

    <v-col
      cols="12"
      md="4"
    >
      <v-row class="match-height">
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalDeposit.change"
            :color="totalDeposit.color"
            :icon="totalDeposit.icon"
            :statistics="totalDeposit.statistics"
            :stat-title="totalDeposit.statTitle"
            :subtitle="totalDeposit.subtitle"
          ></statistics-card-vertical>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalWithdrawal.change"
            :color="totalWithdrawal.color"
            :icon="totalWithdrawal.icon"
            :statistics="totalWithdrawal.statistics"
            :stat-title="totalWithdrawal.statTitle"
            :subtitle="totalWithdrawal.subtitle"
          ></statistics-card-vertical>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalInterrupt.change"
            :color="totalInterrupt.color"
            :icon="totalInterrupt.icon"
            :statistics="totalInterrupt.statistics"
            :stat-title="totalInterrupt.statTitle"
            :subtitle="totalInterrupt.subtitle"
          ></statistics-card-vertical>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalFees.change"
            :color="totalFees.color"
            :icon="totalFees.icon"
            :statistics="totalFees.statistics"
            :stat-title="totalFees.statTitle"
            :subtitle="totalFees.subtitle"
          ></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

// demos
import TrafficCardTotalEarning from './TrafficCardTotalEarning.vue'
import TrafficWeeklyOverview from './TrafficWeeklyOverview.vue'

export default {
  components: {
    StatisticsCardVertical,
    TrafficCardTotalEarning,
    TrafficWeeklyOverview,
  },
  setup() {
    const totalDeposit = {
      statTitle: 'Total Deposits',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Monthly Objective',
      statistics: '$2565.6k',
      change: '+42%',
    }

    const totalWithdrawal = {
      statTitle: 'Total Withdrawals',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Monthly Objective',
      statistics: '$78',
      change: '-15%',
    }

    // vertical card options
    const totalInterrupt = {
      statTitle: 'Total Interrupts',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Monthly Objective',
      statistics: '862',
      change: '-18%',
    }

    const totalFees = {
      statTitle: 'Total Fees',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Monthly Objective',
      statistics: '15',
      change: '-18%',
    }

    return {
      totalDeposit,
      totalWithdrawal,
      totalInterrupt,
      totalFees,
    }
  },
}
</script>
